<template>
  <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('tcbconfiguration.district_wise_beneficiary') }}</h4>
        </template>
        <!-- <template v-slot:searchHeaderAction>
          <router-link class="btn-add" to="beneficiary-entry"><i class="ri-arrow-left-line"></i> {{ $t('tcbconfiguration.beneficiary_entry') }} {{ $t('globalTrans.list') }}</router-link>
        </template> -->
      <b-container fluid>
      <b-row>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-overlay :show="loading">
              <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset">
                <b-row>
                    <!-- -----------------Add More Start------------------- -->
                    <div class="table-wrapper table-responsive mt-2">
                      <table class="table table-striped table-hover table-bordered ">
                        <thead>
                          <tr>
                            <th style="width: 5%;" class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                            <th style="width: 50%;" class="text-center">{{ $t('globalTrans.district') }}</th>
                            <th class="text-center">{{ $t('tcbconfiguration.beneficiary_count') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="beneficiaryData && beneficiaryData.length">
                              <tr v-for="(district, index) in beneficiaryData" :key="index">
                                  <td class="text-center">{{ index+1 }}</td>
                                  <td class="text-center">{{ getDistrictName(district.value) }}</td>
                                  <td class="text-center">
                                      <ValidationProvider name="Beneficiary Count" :vid="`beneficiary_count${index}`" rules="">
                                      <!-- <ValidationProvider name="Beneficiary Count" :vid="`beneficiary_count${index}`" rules="required|min_value:1"> -->
                                          <b-form-group
                                          slot-scope="{ valid, errors }"
                                          >
                                          <b-form-input
                                              style="margin-top: 25px"
                                              id="`beneficiary_count${index}`"
                                              type="number"
                                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                              v-model.number="district.beneficiary"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </td>
                              </tr>
                          </template>
                          <template v-else>>
                            <tr>
                              <td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <!-- -----------------Add More End--------------------- -->
                </b-row>
                <b-row class="text-right mb-3">
                    <b-col>
                        <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                        <router-link :to="{ path: '/license-registration-service/configuration/tcb-configuration/district-wise-beneficiary'}" class="btn btn-danger btn-sm">
                            {{ $t('globalTrans.cancel') }}
                        </router-link>
                    </b-col>
                </b-row>
              </b-form>
            </b-overlay>
          </ValidationObserver>
      </b-row>
  </b-container>
  </card>
  </div>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { districtWiseBeneficiaryStore, districtWiseBeneficiaryList } from '../../../api/routes'

export default {
  components: {
  },
name: 'Form',
props: ['id'],
data () {
  return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      loading: false,
      beneficiaryData: [],
      form: {
          district_id: 0,
          beneficiary: 0
      },
      is_disable: false
  }
},
created () {
  this.getbeneficiary()
},
computed: {
  districtList: function () {
     return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1)
  }
},
watch: {
},
methods: {
  async getbeneficiary () {
    this.loading = true
    this.is_disable = true
    const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, districtWiseBeneficiaryList)
    if (result.success) {
      const districts = result.data

        this.beneficiaryData = this.districtList.map(item => {
            const matchingDistrict = districts.find(district => district.district_id === item.value)

            if (matchingDistrict) {
                return {
                    ...item,
                    beneficiary: matchingDistrict.beneficiary
                }
            }

            return item
        })
    }
     this.loading = false
  },
    async saveUpdate () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, districtWiseBeneficiaryStore, this.beneficiaryData)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.getbeneficiary()
        this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    getDistrictName (disId) {
        const wardObj = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === disId)
        if (wardObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return wardObj.text_bn
            } else {
                return wardObj.text_en
            }
        }
    }
  }
}
</script>
